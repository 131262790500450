import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Legion Principles`}</h2>
    <p>{`Legion Principles are a set of guiding rules for design teams, acting like a compass to ensure our products are not only functional but also engaging and user-friendly. They unify the team’s vision, driving us towards creating exceptional user experiences with a shared understanding of our ultimate goals.`}</p>
    <h3>{`Guiding Your Design Journey`}</h3>
    <p>{`Imagine having a compass in the world of design. Our Legion Principles act as a set of rules that guide the design team in making the right decisions. These principles ensure that the products we create are not only functional but also captivating and user-friendly.`}</p>
    <p>{`Think of them as guiding values that steer us in the right direction, ensuring everyone on the team shares a common understanding of the ultimate goal for the product we are crafting. With Legion Principles, we are united in our vision and driven towards creating exceptional user experiences.`}</p>
    <div className="divi" />
    <h2>{`Dynamic`}</h2>
    <div {...{
      "className": "design-principles-subtitle"
    }}>{`
  `}<img parentName="div" {...{
        "alt": "dynamic-principle",
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/principles/dynamic-1.svg"
      }}></img>{`
  `}<p parentName="div">{`We strive to stay relevant by carefully considering what we deliver, to whom, and in what context. This readiness allows us to adapt and provide the right support and solutions for every situation you encounter.`}</p>
    </div>
    <div className="design-principles-card">
  <div className="grey-card">
    <h3>Honest and Inviting</h3>
    <p>Feel welcomed, not intimidated, so users can easily engage actively with the product.</p>
  </div>
  <div className="grey-card">
    <h3>Simple and Flexible</h3>
    <p>Always adaptable to changes and varying needs over time.</p>
  </div>
  <div className="grey-card">
    <h3>Control and freedom</h3>
    <p>Feel in full control and free to explore the product, making decisions with ease.</p>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Empowerment`}</h2>
    <div {...{
      "className": "design-principles-subtitle"
    }}>{`
  `}<img parentName="div" {...{
        "alt": "empowerment-principle",
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/principles/empowerment-2.svg"
      }}></img>{`
  `}<p parentName="div">{`We aim to be more than just cool; we strive to be useful, sering as a platform for learning and growth. By maintaining quality at the forefront, we enhance experiences, making them valuable in all aspects, be it business, technology, or design.`}</p>
    </div>
    <div className="design-principles-card">
  <div className="grey-card">
    <h3>Embrace Anyone</h3>
    <p>Enables everyone, including those with physical or mental limitations, to access and use with ease.</p>
  </div>
  <div className="grey-card">
    <h3>Powerful, not magical</h3>
    <p>Create amazing predictable experiences where actions are clear and understandable.</p>
  </div>
  <div className="grey-card">
    <h3>Give people superpowers</h3>
    <p>Empower users to overcome obstacles with confidence through abilities and knowledge.</p>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Harmony`}</h2>
    <div {...{
      "className": "design-principles-subtitle"
    }}>{`
  `}<img parentName="div" {...{
        "alt": "harmony-principle",
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/principles/harmony-3.svg"
      }}></img>{`
  `}<p parentName="div">{`We strive to stay relevant by carefully considering what we deliver, to whom, and in what context. This readiness allows us to adapt and provide the right support and solutions for every situation you encounter.`}</p>
    </div>
    <div className="design-principles-card">
  <div className="grey-card">
    <h3>Nature Mental Models</h3>
    <p>Reflect real-world conventions and logic, enabling users to easily undeerstand and interact with the system.</p>
  </div>
  <div className="grey-card">
    <h3>Consistency and standards</h3>
    <p>Layouts, interactions, visuals, and language are standardized and consistent, keeping users familiar with the product.</p>
  </div>
  <div className="grey-card">
    <h3>It's about purpose and function</h3>
    <p>Ensure everything clearly and consistently supports the primary purpose and function of the product or service.</p>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      